import { ALL_CAT_NAME, CATEGORIES_PLAIN, PHOTO_TYPE } from 'site-modules/shared/constants/photoflipper/photoflipper';

export const PHOTO_ROW_THRESHOLD = 4;
export const OVERLAY_TYPE = { REST_PHOTOS: 'rest-photos', PHOTOS_360: 'photos-360' };

export function getPhotoflipperProps({ photoNum, params, altMedia, isPreprod, hasSpinPhotos, is360Tab }) {
  const { make, model, year, submodel } = params;

  return {
    makeSlug: make,
    modelSlug: model,
    year,
    submodel,
    initialMediaType: PHOTO_TYPE,
    show360Photos: hasSpinPhotos,
    galleryPfOptions: {
      ...(isPreprod ? { altMedia } : {}),
      pageNum: 1,
      selectedCategory: is360Tab ? CATEGORIES_PLAIN.PHOTOS_360 : ALL_CAT_NAME,
      gallerySubmodel: submodel,
      currentSlide: photoNum,
      isGalleryPf: true,
    },
    isPreprod,
  };
}
