export const getCardWidth = container => {
  const slickSlide = container.querySelector('.slick-slide:not(.d-none)');
  return slickSlide && slickSlide.offsetWidth;
};

export const getParentWidth = (container, cardWidth) => {
  const slickList = container.querySelector('.slick-list');
  const parentWindowWidth = slickList && slickList.offsetWidth;
  return parentWindowWidth % cardWidth > 10 ? parentWindowWidth - cardWidth : parentWindowWidth;
};

export const handleScrollLeftPos = (container, updateCallback, fireTracking) => {
  const slickList = container.querySelector('.slick-list');
  const sliderWidth = slickList.offsetWidth;
  const scrollLeft = slickList.scrollLeft;
  const scrollWidth = slickList.scrollWidth;
  const prevBtn = container.querySelector('.left.carousel-control');
  const nextBtn = container.querySelector('.right.carousel-control');
  if (scrollLeft === 0) {
    prevBtn.classList.add('d-none');
  } else {
    prevBtn.classList.remove('d-none');
  }
  if (scrollWidth === sliderWidth + scrollLeft) {
    nextBtn.classList.add('d-none');
  } else {
    nextBtn.classList.remove('d-none');
  }
  updateCallback(scrollLeft, fireTracking);
};

export const collapseEmptyCarouselAdSlot = (container, adSlotClass) => {
  const adSlots = container.querySelectorAll(adSlotClass);
  for (let i = 0; i < adSlots.length; i += 1) {
    const adSlot = adSlots[i];
    const iframe = adSlot.querySelector('iframe');

    if (!iframe) {
      adSlot.classList.add('d-none');
    } else if (iframe.clientWidth < 10 && iframe.style.minWidth !== '100%') {
      adSlot.classList.add('d-none');
    } else {
      adSlot.classList.remove('d-none');
    }
  }
};
