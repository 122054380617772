import get from 'lodash/get';
import { getNewUsedState } from 'site-modules/shared/utils/new-used-state';
import { isUsedUsedPubState } from 'site-modules/shared/utils/core-page/is-used-pub-state';
import { isPreProd } from 'site-modules/shared/utils/publication-states';

/**
 * submodelName includes model name example: Accord Sedan
 * so we replace a model name with an empty string to get a submodel name
 * unless the submodelName is the same as the model name in order to
 * avoid returning an empty string.
 *
 * @param {String} submodelName
 * @param {String} modelName
 * @return {String}
 */
export function getSubmodelIdentifier(submodelName, modelName) {
  if (!submodelName || submodelName === modelName) {
    return submodelName;
  }

  return submodelName.replace(modelName, '').trim();
}

/**
 * The function is needed for matching params' submodel with all available submodels of MMY
 * @param {String} submodel
 * @param {Array} submodels
 * @return {String, undefined}
 */
export function getSubmodelName(submodel, submodels = []) {
  const submodelObj = submodels && submodels.find(({ slug }) => slug === submodel);
  if (!submodelObj) {
    return undefined;
  }

  return getSubmodelIdentifier(submodelObj.name, get(submodelObj, 'model.name'));
}

/**
 * SEOT-3060 Get Submodel type and check if it is one of
 * the chal submodels to change breadcrumb
 */
export function seot3630ChalSubmodelMatch(edmundsTypeCategories) {
  const seot3630ChalSubmodelCategories = ['Sedan', 'Coupe', 'Convertible', 'Minivan'];
  const seot3630ChalSubmodels = edmundsTypeCategories
    ? Object.values(edmundsTypeCategories)
        .map(category => category.name)
        .filter(category => seot3630ChalSubmodelCategories.includes(category))
    : [];
  return seot3630ChalSubmodels?.[0] ?? null;
}

/**
 * Clone params object and extends with isUsed and isNewUsed property.
 *
 * @param {Object} router
 * @param {String} year
 * @param {boolean} isUsed
 * @returns {Object}
 */
export function cloneParamsWithPubState({ params, year, isUsed }) {
  const isNewUsed = getNewUsedState(isUsed, year);
  const isUsedUsed = isUsedUsedPubState(year);

  return {
    isUsed,
    isNewUsed,
    isUsedUsed,
    ...params,
    year,
  };
}

export function getPublicationStateKey(pubStates, isUsed) {
  if (isPreProd(pubStates)) {
    return 'preprod';
  }

  return isUsed ? 'used' : 'new';
}
