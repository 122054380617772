import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, get } from 'lodash';
import { numberWithCommasForString } from 'site-modules/shared/utils/string';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { getPlural } from 'client/utils/plural';

const MILEAGE_PER_YEAR = 14000;
const WARRANTY_TOOLTIP = {
  BATTERY:
    'The federal government requires that EV batteries be warrantied for a minimum of eight years or 100,000 miles. The EV battery warranty includes replacement if your battery capacity drops below a certain percentage of the original capacity.',
  HYBRID_COMPONENT:
    'Hybrid batteries are federally mandated to carry at least 8 years or 100,000 miles of warranty coverage from the manufacturer. Batteries that lose capacity more rapidly than expected should be eligible for replacement under warranty. Coverage of additional hybrid components beyond the battery itself will vary by automaker.',
};

export function BatteryLifeInsight({ batteryWarranty, hybridComponentsWarranty, usedYears, onTooltipOpen }) {
  const currentWarranty = batteryWarranty || hybridComponentsWarranty;

  const maxYears = get(currentWarranty, 'maxYears');
  const maxYearsType = get(currentWarranty, 'maxYearsType');
  const maxMileage = get(currentWarranty, 'maxMileage');
  const maxMileageType = get(currentWarranty, 'maxMileageType');
  const hasWarrantyData = !!(maxMileage || maxMileageType === 'UNLIMITED' || maxYears || maxYearsType === 'UNLIMITED');

  const yearsRemaining = maxYears - usedYears;
  const mileageRemaining = maxMileage - usedYears * MILEAGE_PER_YEAR;

  let yearWarrantyText =
    maxYearsType === 'UNLIMITED' ? 'Warranty period is unlimited' : `${maxYears} ${getPlural('yr', 'yrs', maxYears)}`;
  if (!maxYears && maxYearsType !== 'UNLIMITED') {
    yearWarrantyText = 'Warranty period not available';
  }

  let mileageWarrantyText =
    maxMileageType === 'UNLIMITED' ? 'Warranty mileage is unlimited' : `${numberWithCommasForString(maxMileage)} miles`;
  if (!maxMileage && maxMileageType !== 'UNLIMITED') {
    mileageWarrantyText = 'Warranty mileage not available';
  }

  const separator =
    (!maxYears && maxYearsType !== 'UNLIMITED') ||
    (!maxMileage && maxMileageType !== 'UNLIMITED') ||
    maxYearsType === 'UNLIMITED' ||
    maxMileageType === 'UNLIMITED'
      ? '/'
      : 'or';

  let usedDescriptionText;
  switch (true) {
    case yearsRemaining > 0 && mileageRemaining > 0: {
      usedDescriptionText = (
        <Fragment>
          Estimated battery warranty remaining is {yearsRemaining} {getPlural('year', yearsRemaining)} or{' '}
          {numberWithCommasForString(`${mileageRemaining}`)} {getPlural('mile', mileageRemaining)} for this car.
        </Fragment>
      );
      break;
    }
    case !!maxYears && yearsRemaining <= 0: {
      usedDescriptionText = <Fragment>Exceeded original {maxYears} year warranty.</Fragment>;
      break;
    }
    default: {
      usedDescriptionText = (
        <Fragment>
          {!!maxYears && (
            <Fragment>
              Estimated battery warranty remaining is {yearsRemaining} {getPlural('year', yearsRemaining)}.
            </Fragment>
          )}
          {!!maxMileage && mileageRemaining <= 0 && (
            <Fragment>Exceeded original {numberWithCommasForString(`${mileageRemaining}`)} mileage warranty.</Fragment>
          )}
          {!!maxMileage && mileageRemaining > 0 && (
            <Fragment>
              Estimated battery warranty remaining is {numberWithCommasForString(`${mileageRemaining}`)}{' '}
              {getPlural('mile', mileageRemaining)}.
            </Fragment>
          )}
        </Fragment>
      );
    }
  }

  return (
    <div className="battery-life-insight" data-tracking-parent="edm-entry-battery-life">
      <div className={classnames({ 'heading-4 text-center mb-1': hasWarrantyData })}>
        {hasWarrantyData ? (
          <Fragment>
            {yearWarrantyText} {separator} {mileageWarrantyText}
            <TooltipItem
              className="top-0 medium"
              item={{
                placement: 'bottom',
                text: batteryWarranty ? WARRANTY_TOOLTIP.BATTERY : WARRANTY_TOOLTIP.HYBRID_COMPONENT,
              }}
              id="battery-life-insight-warranty"
              onTooltipOpen={onTooltipOpen}
            />
          </Fragment>
        ) : (
          'No battery warranty information available'
        )}
      </div>
      {hasWarrantyData && (
        <div className={classnames('medium', { 'mb-1': usedYears })}>
          According to GeoTab’s data, if the observed degradation rates are maintained, the vast majority of batteries
          will outlast the usable life of the vehicle.
        </div>
      )}
      {!!usedYears && (
        <div className="medium">
          {usedDescriptionText}
          {!!(maxMileage || maxYears) && (
            <TooltipItem
              className="top-0"
              item={{
                placement: 'bottom',
                text:
                  'Warranty remaining value is based on the vehicle year, and on driving 14,000 miles per year. Confirm exact warranty coverage for each vehicle with the dealers and the manufacturer before purchasing.',
              }}
              id="battery-life-insight-remaining"
              onTooltipOpen={onTooltipOpen}
            />
          )}
        </div>
      )}
    </div>
  );
}

BatteryLifeInsight.propTypes = {
  batteryWarranty: PropTypes.shape({
    maxYears: PropTypes.string,
    maxYearsType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
    maxMileage: PropTypes.string,
    maxMileageType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
  }),
  hybridComponentsWarranty: PropTypes.shape({
    maxYears: PropTypes.string,
    maxYearsType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
    maxMileage: PropTypes.string,
    maxMileageType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
  }),
  usedYears: PropTypes.number,
  onTooltipOpen: PropTypes.func,
};

BatteryLifeInsight.defaultProps = {
  batteryWarranty: null,
  hybridComponentsWarranty: null,
  usedYears: 0,
  onTooltipOpen: noop,
};
