import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export function TextOverlay({ text, className }) {
  return (
    !!text && (
      <div className={classnames('overlay d-flex align-items-center h-100 w-100 rounded-12', className)}>{text}</div>
    )
  );
}

TextOverlay.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

TextOverlay.defaultProps = {
  text: null,
  className: 'justify-content-center size-20 text-white',
};
