import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { formatFuelCosts } from 'site-modules/shared/utils/range-and-cost';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Select } from 'site-modules/shared/components/select/select';

import './cost-part.scss';

const COST_ICON_BY_ENGINE_TYPE_MAP = {
  electric: 'icon-power2',
  'plug-in hybrid': 'icon-power2',
  hybrid: 'icon-hybrid_mpg',
  default: 'icon-gas',
};

export function CostPart({ fuelCostsData, averageCategory, style, selectProps, tooltipId, onTooltipOpen, stateName }) {
  const { makeName, modelName, year, trim, name } = style;

  const engineType = get(fuelCostsData, 'vehicleData.engineType');
  const costPerMonth = get(fuelCostsData, 'fuelCosts.costPerMonth', 0);
  const avgCostPerMonth = get(fuelCostsData, 'statistics.byBodyTypeAndSize.combustionEngines.averageCostPerMonth', 0);
  const { primary, secondary } = formatFuelCosts(fuelCostsData);

  const tooltipText = `Cost to drive estimates for the ${year} ${makeName} ${modelName} ${name} and comparison vehicles are based on 15,000 miles per year (with a mix of 55% city and 45% highway driving) and energy estimates of ${getPriceString(
    primary.price,
    'N/A',
    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  )} ${primary.fuelGrade} ${
    secondary.price
      ? `and ${getPriceString(secondary.price, 'N/A', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${
          secondary.fuelGrade
        } `
      : ''
  }in ${stateName}.`;

  return (
    <Fragment>
      <h2 className="mb-0_25 heading-4">
        Cost to Drive
        <TooltipItem
          id={tooltipId}
          iconClassName="text-primary-darker"
          innerClassName="p-1"
          tooltipClassName="px-1"
          onTooltipOpen={onTooltipOpen}
          item={{
            placement: 'bottom',
            text: tooltipText,
          }}
        />
      </h2>
      <div className="text-gray-darker">Monthly estimates based on costs in {stateName}</div>
      <div className="my-auto pt-1_5">
        <Row className="pos-r">
          <Col xs={6} className="text-center">
            <i
              className={classnames(
                'size-30 text-primary-gradient mb-0_5',
                COST_ICON_BY_ENGINE_TYPE_MAP[engineType] || COST_ICON_BY_ENGINE_TYPE_MAP.default
              )}
              aria-hidden
            />
            <div className="text-primary-gradient medium fw-bold" aria-live="polite" aria-atomic="true">
              {costPerMonth ? (
                <Fragment>
                  <span className="size-24">{getPriceString(costPerMonth)}</span>
                  /mo
                </Fragment>
              ) : (
                'Not EPA tested'
              )}{' '}
              <span className="visually-hidden">
                &nbsp;for {modelName} {trim}
              </span>
            </div>
            <div className="text-gray-darker small pos-r">
              <div className="model-trim-label text-primary-darker pos-r bg-white" aria-hidden>
                {modelName} {trim} <i className="icon-arrow-down4 size-10" aria-hidden />
              </div>
              <Select
                {...selectProps}
                name="cost-trim-select"
                className="cost-select border-0 rounded h-100 w-100 pos-a left-0 top-0"
                labelText="Select style to calculate monthly estimates"
                isLabelHidden
              />
            </div>
          </Col>
          {!!avgCostPerMonth && (
            <Fragment>
              <div className="vs-separator pos-a center-x h-100">
                <div className="pos-a center-xy bg-white fw-bold text-gray-dark medium py-0_25">vs</div>
              </div>
              <Col xs={6} className="text-center text-gray-darker">
                <i className={classnames('size-30 mb-0_5', COST_ICON_BY_ENGINE_TYPE_MAP.default)} aria-hidden />
                <div className="medium">
                  <span className="size-24">{getPriceString(avgCostPerMonth)}</span>
                  /mo
                </div>
                <div className="text-gray-darker small">
                  {averageCategory ? `Avg. ${averageCategory}` : 'Similar Gas Vehicles'}
                </div>
              </Col>
            </Fragment>
          )}
        </Row>
      </div>
    </Fragment>
  );
}

CostPart.propTypes = {
  style: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    makeName: PropTypes.string,
    modelName: PropTypes.string,
    trim: PropTypes.string,
    name: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onTooltipOpen: PropTypes.func.isRequired,
  fuelCostsData: VehicleEntities.StyleFuelCostData.isRequired,
  tooltipId: PropTypes.string.isRequired,
  averageCategory: PropTypes.string,
  selectProps: PropTypes.shape({
    onChange: PropTypes.func,
    valueKey: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      })
    ),
  }),
  stateName: PropTypes.string,
};

CostPart.defaultProps = {
  averageCategory: null,
  selectProps: null,
  tooltipId: null,
  stateName: null,
};
