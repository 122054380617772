export const VEHICLE_MILEAGE_PER_YEAR = 12000;

export const SEO_IMAGE_SIZE = { width: 1280, height: 855 };

export const CORE_GALLERY_SIZE = {
  DESKTOP: { HERO: { width: 815, height: 543 }, ROW: { width: 175, height: 117 } },
  MOBILE: { HERO: { width: 600, height: 400 }, ROW: { width: 600, height: 400 } },
};

export const MENU_HASH = {
  OVERVIEW: 'subnav-overview',
  TRIM_SUMMARY_DESKTOP: 'subnav-trim-summary-desktop',
  TRIM_SUMMARY_MOBILE: 'subnav-trim-summary-mobile',
  REVIEWS: 'subnav-reviews',
  RATING_SCORECARD: 'subnav-rating-scorecard',
  CONSUMER_REVIEWS: 'subnav-consumer-reviews',
  PRICING: 'subnav-pricing',
  INVENTORY: 'subnav-inventory',
  FEATURES: 'subnav-features',
  COMPETITION: 'subnav-competition',
  SAFETY: 'subnav-safety',
  EV_INSIGHTS: 'subnav-ev-insights',
};
