import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, minBy, sortBy } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildPopularStylesPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { VisitorEntities, VisitorModel } from 'client/data/models/visitor';
import { PageModel } from 'client/data/models/page';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { getMpgUrl } from 'site-modules/shared/utils/core-link-constructor';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { Select } from 'site-modules/shared/components/select/select';
import { Link } from 'site-modules/shared/components/link/link';
import { InsightsCards } from './insights-cards/insights-cards';

import './ev-insights.scss';

function EvInsightsUI({
  className,
  vehicle,
  styles,
  sharedTrimName,
  visitorLocation,
  setModelValue,
  isPluginHybrid,
  isUsed,
  isMobile,
}) {
  const onStyleChange = useCallback(
    ({ trim }) => {
      setModelValue('sharedValues.trimName', PageModel, trim);
    },
    [setModelValue]
  );

  if (!styles) {
    return null;
  }

  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
  } = vehicle;
  const currentStyle =
    (styles && styles.find(({ trim }) => trim === sharedTrimName)) || minBy(styles, 'price.baseMSRP');

  return (
    <ScrollElement
      id="subnav-ev-insights"
      className={classnames('ev-insights bg-cool-gray-90 py-1_5 px-1 rounded-12', className)}
      data-tracking-parent="edm-entry-ev-insights"
    >
      <div className="mb-1">
        <i className="type-icon size-20 text-blue-40 icon-power2 mr-0_5" aria-hidden />
        <h2 className="heading-4 d-inline mr-md-1">
          {isUsed && year} {makeName} {modelName} {!modelName.includes('EV') && !isPluginHybrid && 'EV'}{' '}
          {isPluginHybrid && 'PHEV'} Insights
        </h2>
        <div className="d-md-inline-block mt-0_75 mt-md-0">
          Trim:
          <div className="d-inline-block pos-r ml-0_5">
            <div className="model-trim-label text-blue-40 pos-r bg-cool-gray-90">
              {modelName} {currentStyle.trim} <i className="icon-arrow-down4 size-10" aria-hidden />
            </div>
            <Select
              onChange={onStyleChange}
              valueKey="name"
              value={currentStyle.name}
              options={styles}
              name="ev-insights-trim-select"
              className="ev-insights-select border-0 rounded h-100 w-100 pos-a left-0 top-0"
              labelText="Select style"
              data-tracking-id="style_select"
              isLabelHidden
            />
          </div>
        </div>
      </div>
      <InsightsCards
        styleId={currentStyle.id}
        vehicle={vehicle}
        className={className}
        visitorLocation={visitorLocation}
        isPluginHybrid={isPluginHybrid}
        isUsed={isUsed}
        isMobile={isMobile}
      />
      <Link
        to={getMpgUrl({ makeSlug, modelSlug, year, styleId: currentStyle.id })}
        className="text-primary-darker"
        data-tracking-id="mpg_model_select"
      >
        See All {isPluginHybrid ? 'PHEV' : 'EV'} Insights
        <i className="icon-arrow-right3 small ml-0_25" aria-hidden />
      </Link>
    </ScrollElement>
  );
}

EvInsightsUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  setModelValue: PropTypes.func.isRequired,
  styles: VehicleEntities.PopularStyles,
  sharedTrimName: PropTypes.string,
  className: PropTypes.string,
  visitorLocation: VisitorEntities.Location,
  isPluginHybrid: PropTypes.bool,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

EvInsightsUI.defaultProps = {
  className: null,
  styles: null,
  sharedTrimName: null,
  visitorLocation: null,
  isPluginHybrid: false,
  isUsed: false,
  isMobile: false,
};

export const propsAreEqual = (prevProps, { styles }) => !styles;
export const EvInsights = connectToModel(React.memo(EvInsightsUI, propsAreEqual), {
  visitorLocation: bindToPath('location', VisitorModel),
  sharedTrimName: bindToPath('sharedValues.trimName', PageModel),
  styles: bindToPath(
    ({ vehicle }) => buildPopularStylesPath(getParamsFromVehicle(vehicle)),
    VehicleModel,
    popularStyles =>
      sortBy(
        popularStyles.map((style, index) => ({
          ...style,
          label: `${style.name}${index ? '' : ' (Most Popular)'} - ${getPriceString(
            get(style, 'price.baseMSRP')
          )} MSRP`,
        })),
        'price.baseMSRP'
      )
  ),
});
