import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, groupBy, orderBy } from 'lodash';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildPopularStylesPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { PageModel } from 'client/data/models/page';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { RangeAndCostCard } from './range-and-cost-card';

const CREATIVE_ID = 'edm-entry-range-and-cost';

function RangeAndCostUI({
  styles,
  setModelValue,
  sharedTrimName,
  vehicle: {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
  },
  className,
}) {
  const onStyleChange = useCallback(
    newStyle => {
      setModelValue('sharedValues.trimName', PageModel, newStyle.trim);
      TrackingHandler.fireEvent('style_select', {
        event_data: {
          action_cause: TrackingConstant.SELECT_CHANGE,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          creative_id: CREATIVE_ID,
          value: newStyle.name,
        },
      });
    },
    [setModelValue]
  );

  if (!styles) {
    return null;
  }

  const selectedStyle = styles && styles.find(({ trim }) => trim === sharedTrimName);
  const currentStyle = selectedStyle || styles[0];

  return (
    <RangeAndCostCard
      style={{
        makeName,
        modelName,
        makeSlug,
        modelSlug,
        year,
        id: currentStyle.id,
        trim: currentStyle.trim,
        name: currentStyle.name,
      }}
      selectProps={{
        onChange: onStyleChange,
        valueKey: 'name',
        value: currentStyle.name,
        optionsGrouped: groupBy(styles, 'trim'),
      }}
      className={className}
      creativeId={CREATIVE_ID}
      isDefaultStyle={currentStyle !== selectedStyle}
    />
  );
}

RangeAndCostUI.propTypes = {
  vehicle: PropTypes.shape({
    make: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    model: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    edmundsTypeCategories: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  }).isRequired,
  setModelValue: PropTypes.func.isRequired,
  className: PropTypes.string,
  styles: VehicleEntities.PopularStyles,
  sharedTrimName: PropTypes.string,
};

RangeAndCostUI.defaultProps = {
  className: null,
  styles: null,
  sharedTrimName: null,
};

export const RangeAndCost = connectToModel(RangeAndCostUI, {
  sharedTrimName: bindToPath('sharedValues.trimName', PageModel),
  styles: bindToPath(({ params }) => buildPopularStylesPath(params), VehicleModel, popularStyles =>
    orderBy(
      popularStyles.map((style, index) => ({
        ...style,
        label: `${style.name}${index ? '' : ' (Most Popular)'} - ${getPriceString(get(style, 'price.baseMSRP'))} MSRP`,
      })),
      'price.baseMSRP'
    )
  ),
});
