import { DFPManager } from '@edmunds/react-dfp';
import { EventToolbox } from 'client/utils/event-toolbox';
import { FILMSTRIP_CAROUSEL_EVENTS } from 'site-modules/shared/components/core-page/sponsored-cars/filmstrip-carousel/events';
import { getCreativeId } from 'client/engagement-handlers/helper';
import { shouldRefreshAds } from 'client/actions/ads-refresh';
import { RefreshRules } from 'client/site-modules/shared/components/ad-unit/utils/refresh-rules';
import { debounce, get } from 'lodash';

const REFRESH_TIME_VALUE = 500;
const TRIGGER = 'carousel';

export function setupSwipeHandler() {
  return function handleCarouselSwipe(event) {
    const { target, data = {} } = event.detail;
    const {
      currentIndex,
      nextIndex,
      totalAdCount,
      contentType,
      contentDetails,
      customEventData = {},
      photoType,
      actionCause,
    } = data;

    const carousel = target;
    const nextSlideEl = carousel && carousel.querySelector(`.slick-slide[data-index="${nextIndex}"]`);
    const trackingValueElement = nextSlideEl ? nextSlideEl.querySelector('[data-carousel-tracking-value]') : '';
    const value =
      nextSlideEl &&
      (trackingValueElement ? trackingValueElement.innerText.toLowerCase() : nextSlideEl.innerText.toLowerCase());
    const creativeId = getCreativeId(carousel);
    const eventData = {
      carousel_index: nextIndex + 1,
      carousel_prev_index: currentIndex + 1,
      carousel_total: totalAdCount,
      value,
      creative_id: creativeId,
      action_category: 'user',
      action_name: `view_${contentType}`,
      subaction_name: `view_${contentDetails}`,
      action_cause: actionCause || 'swipe',
      photo_type: photoType,
      ...customEventData,
    };

    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: eventData,
    });
  };
}

export function refreshAds(timeValue, getState) {
  return debounce(event => {
    const noAdsRefresh = get(event, 'detail.data.noAdsRefresh');
    if (noAdsRefresh || !(RefreshRules.refreshOn.carousel.size && shouldRefreshAds(TRIGGER, getState))) {
      return;
    }
    const refreshRulesRefreshOnCarousel = Array.from(RefreshRules.refreshOn.carousel);
    DFPManager.refresh(...refreshRulesRefreshOnCarousel);
  }, timeValue);
}

/**
 * Carousel events handler
 */
export const FilmStripCarouselEngagementHandler = {
  /**
   * Setups carousel events handler
   *
   * @param  {Function} getState Gets redux state
   * @return {void}
   */
  init({ getState }) {
    EventToolbox.on(FILMSTRIP_CAROUSEL_EVENTS.SWIPE, setupSwipeHandler());
    EventToolbox.on(FILMSTRIP_CAROUSEL_EVENTS.SWIPE, refreshAds(REFRESH_TIME_VALUE, getState));
  },
};
