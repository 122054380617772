import React from 'react';
import PropTypes from 'prop-types';

export function InsightCard({ tag: Tag, id, heading, badge, children, creativeId }) {
  return (
    <Tag
      id={id}
      className="insight-card pos-r py-1_5 px-1_5 mb-1 bg-white rounded-12"
      data-tracking-parent={creativeId}
    >
      <div className="d-flex justify-content-between align-items-center mb-1_5">
        <h3 className="heading-5 mb-0">{heading}</h3>
        {badge}
      </div>
      {children}
    </Tag>
  );
}

InsightCard.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  id: PropTypes.string,
  badge: PropTypes.node,
  creativeId: PropTypes.string,
};

InsightCard.defaultProps = {
  tag: 'div',
  id: undefined,
  creativeId: null,
  badge: null,
};
