import { Component, createElement } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageDefinition } from 'site-modules/shared/pages/page';
import {
  VehicleModel,
  VehicleEntities,
  buildVehiclePath,
  buildDefaultVehiclePath,
} from 'client/data/models/vehicle-v2';
import { updateVehicle, buildContextVehicleFromMakeModelSubmodelYear } from 'client/actions/vehicle';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { isUsed as isUsedPubState } from 'site-modules/shared/utils/publication-states';

const mapDispatchToProps = dispatch => ({
  /**
   * @param makeModelSubmodelYear
   */
  updateVehicle: makeModelSubmodelYear => {
    const isUsed = isUsedPubState(get(makeModelSubmodelYear, 'pubStates', {}));
    const vehicleForContext = buildContextVehicleFromMakeModelSubmodelYear(makeModelSubmodelYear, { isUsed });
    dispatch(updateVehicle(vehicleForContext));
  },
});

export function corePageDecorator(options, params) {
  const { component } = options;

  class CorePageComponentUI extends Component {
    constructor(props) {
      super(props);

      this.props.updateVehicle(this.props.vehicle);

      this.newParams = !params.year ? { ...params, year: get(this.props, 'vehicle.year') } : params;
    }

    render() {
      return createElement(component, { ...this.props, params: this.newParams });
    }
  }

  CorePageComponentUI.propTypes = {
    ...component.propTypes,
    vehicle: VehicleEntities.MakeModelSubmodelYear,
    updateVehicle: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  CorePageComponentUI.defaultProps = {
    vehicle: null,
  };

  const CorePageComponent = withRouter(
    connect(
      null,
      mapDispatchToProps
    )(
      connectToModel(CorePageComponentUI, {
        vehicle: bindToPath(
          () => (params.year ? buildVehiclePath(params) : buildDefaultVehiclePath(params)),
          VehicleModel
        ),
      })
    )
  );

  return pageDefinition({
    ...options,
    component: CorePageComponent,
  });
}
