/**
 * Check for newUsed page.
 * @param {Boolean} isUsed
 * @param {Number} year
 * @returns {Boolean}
 */
export function getNewUsedState(isUsed, year) {
  const currentYear = new Date().getFullYear();

  return isUsed && (Number(year) === currentYear || Number(year) === currentYear - 1);
}
