import { get, isUndefined } from 'lodash';
import { DEFAULT_PERSONALIZATION_PARAMS } from 'site-modules/shared/constants/range-and-cost';

const FUEL_GRADE_MAP = {
  FULL: {
    D: 'per gallon for diesel',
    R: 'per gallon for regular unleaded',
    P: 'per gallon for premium unleaded',
    E: 'per kWh for electricity',
    AVERAGE: 'per gallon average',
  },
  SHORT: {
    D: '/gallon',
    R: '/gallon',
    P: '/gallon',
    E: '/kWh',
    AVERAGE: '/gallon',
  },
};

export function formatFuelCosts(fuelCostsData, { isShortFuelGrade } = {}) {
  const fuelGradeMap = isShortFuelGrade ? FUEL_GRADE_MAP.SHORT : FUEL_GRADE_MAP.FULL;

  const formattedFuelCosts = {
    primary: {
      fuelGrade:
        fuelGradeMap[get(fuelCostsData, 'fuelCosts.primary.fuelGrade') || get(fuelCostsData, 'fuelCosts.fuelGrade')],
      price:
        get(fuelCostsData, 'fuelCosts.primary.pricePerKWh') ||
        get(fuelCostsData, 'fuelCosts.primary.pricePerGallon') ||
        get(fuelCostsData, 'fuelCosts.pricePerKWh') ||
        get(fuelCostsData, 'fuelCosts.pricePerGallon'),
    },
    secondary: {
      fuelGrade: fuelGradeMap[get(fuelCostsData, 'fuelCosts.secondary.fuelGrade')],
      price:
        get(fuelCostsData, 'fuelCosts.secondary.pricePerKWh') ||
        get(fuelCostsData, 'fuelCosts.secondary.pricePerGallon'),
    },
  };

  if (!formattedFuelCosts.secondary.fuelGrade && formattedFuelCosts.primary.fuelGrade === fuelGradeMap.E) {
    formattedFuelCosts.secondary = {
      fuelGrade: fuelGradeMap.AVERAGE,
      price: parseFloat(
        get(fuelCostsData, 'statistics.byBodyTypeAndSize.combustionEngines.averagePricePerGallon', 0).toFixed(2)
      ),
    };
  }

  return formattedFuelCosts;
}

export function calculateTailpipeEmissions({
  primaryFuelGrade,
  engineType,
  dailyCommute,
  cityPercents = DEFAULT_PERSONALIZATION_PARAMS.cityPercents,
  milesPerYear = DEFAULT_PERSONALIZATION_PARAMS.milesPerYear,
  range,
  mpgData: { city, highway },
}) {
  let emissions;
  switch (true) {
    case !isUndefined(dailyCommute): {
      const dailyRange = range > dailyCommute ? dailyCommute : range;
      emissions =
        (0.0097962406 / (city * (cityPercents / 100) + highway * ((100 - cityPercents) / 100))) *
        (milesPerYear - dailyRange * 365);
      break;
    }
    case ['R', 'P', 'D'].includes(primaryFuelGrade) || ['natural gas', 'natural gas (CNG)'].includes(engineType): {
      let factor;
      switch (primaryFuelGrade) {
        case 'R':
        case 'P': {
          factor = 0.0097962406;
          break;
        }
        case 'D': {
          factor = 0.011221529;
          break;
        }
        default: {
          factor = 0.0097962406 * 0.6;
        }
      }

      emissions = (factor / (city * (cityPercents / 100) + highway * ((100 - cityPercents) / 100))) * milesPerYear;
      break;
    }
    default: {
      break;
    }
  }

  return Math.max(emissions, 0);
}
