/**
 * Return true if vehicle year older than 3 years. Should only be used for strange ad customization.
 * DO NOT USE THIS FOR ROUTES.
 *
 * @param {number} vehicleYear
 * @returns {boolean}
 */
export function isUsedUsedPubState(vehicleYear) {
  const currentYear = new Date().getFullYear();
  const isOlderThanThreeYears = currentYear - Number(vehicleYear) >= 3;

  return isOlderThanThreeYears;
}
