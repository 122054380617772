import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingConstant } from 'client/tracking/constant';
import { IncentivesWizardMap } from 'client/tracking/maps/incentives-wizard';
import {
  IncentivesWizardEntities,
  IncentivesWizardModel,
  IncentivesWizardPaths,
} from 'client/data/models/incentives-wizard';
import {
  questions,
  RESULTS_SCREEN_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { generateNewFlatUrl } from 'site-modules/shared/utils/srp-link-constructor';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { getCreativeId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-creative-id';
import { getPotentialRebatesSum } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-potential-rebates-sum';
import { IncentivesWizardDrawer } from 'site-modules/shared/components/inventory/incentives-wizard/incentives-wizard-drawer/incentives-wizard-drawer';
import { StartScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/start-screen';

const BUTTON_CTA = 'Check my eligibility';
const INVENTORY_TYPE = { true: 'used', false: 'new' };

export function IncentivesWizardInsight({
  incentives,
  setModelValue,
  styleId,
  makeName,
  modelName,
  makeSlug,
  modelSlug,
  year,
  engineType,
  zipCode,
  isUsed,
  isMobile,
}) {
  const [isModelOpen, toggleModelOpen] = useToggle(false);
  const [saveSumRebates, setSaveSumRebates] = useState(null);
  const [setDrawerTimeout] = useTimeout();
  const creativeId = incentives && getCreativeId({ inventoryType: INVENTORY_TYPE[isUsed], incentives });
  const engineTypes = [engineType?.toLowerCase()];

  const openWizard = useCallback(() => {
    TrackingHandler.tryAddMap(IncentivesWizardMap);

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_FILL_FORM,
        subaction_name: TrackingConstant.OPEN_INCENTIVES_MATCHER,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: creativeId,
        value: BUTTON_CTA,
      },
    });

    setDrawerTimeout(async () => {
      await setModelValue(IncentivesWizardPaths.getVehicle(), IncentivesWizardModel, {
        styleId: styleId?.toString(),
        inventoryCode: INVENTORY_TYPE[isUsed].toUpperCase(),
        engineTypes,
        year,
        makeSlug,
        modelSlug,
      });
      await setModelValue(IncentivesWizardPaths.getIncentivesWizardData(), IncentivesWizardModel, incentives);
      toggleModelOpen();
    }, 0);
  }, [
    creativeId,
    setDrawerTimeout,
    setModelValue,
    styleId,
    isUsed,
    engineTypes,
    year,
    makeSlug,
    modelSlug,
    incentives,
    toggleModelOpen,
  ]);

  const wizardQuestions = useMemo(
    () => ({
      ...questions,
      [RESULTS_SCREEN_ID]: {
        ...questions[RESULTS_SCREEN_ID],
        srpLink: isUsed
          ? getCoreUrl({ makeSlug, modelSlug, year })
          : generateNewFlatUrl({ make: makeSlug, model: modelSlug }),
      },
    }),
    [isUsed, makeSlug, modelSlug, year]
  );

  const incentivesAmount = saveSumRebates === null ? incentives && getPotentialRebatesSum(incentives) : saveSumRebates;

  return (
    <div className="incentives-wizard-insight" data-tracking-parent={creativeId}>
      {!incentivesAmount && <div>No rebates available in {zipCode}</div>}
      {!!incentivesAmount && (
        <Fragment>
          <div className="mb-0_75">
            Maximize savings on your {makeName} {modelName}.
          </div>
          <StartScreen
            stepConfig={{
              hideImage: true,
              btnCopy: BUTTON_CTA,
              className: null,
              btnClassName: 'align-self-center py-0_5 px-1',
              forceAmount: incentivesAmount,
            }}
            context={{ make: makeName }}
            allIncentives={incentives}
            onAnswer={openWizard}
            creativeId={creativeId}
          />
        </Fragment>
      )}
      <IncentivesWizardDrawer
        questions={wizardQuestions}
        onRebatesSaveSum={setSaveSumRebates}
        onToggle={toggleModelOpen}
        isOpen={isModelOpen}
        isMobile={isMobile}
        make={makeName}
        model={modelName}
        type={INVENTORY_TYPE[isUsed]}
        engineTypes={engineTypes}
        year={parseInt(year, 10)}
        creativeId={creativeId}
        initialStepIndex={1}
      />
    </div>
  );
}

IncentivesWizardInsight.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  styleId: PropTypes.number.isRequired,
  makeName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  makeSlug: PropTypes.string.isRequired,
  modelSlug: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  incentives: IncentivesWizardEntities.IncentivesData,
  engineType: PropTypes.string,
  zipCode: PropTypes.string,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

IncentivesWizardInsight.defaultProps = {
  incentives: null,
  engineType: null,
  zipCode: null,
  isUsed: false,
  isMobile: false,
};
