import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export function RatingScorecardEmbed({ children, childrenClassName, isEmbed }) {
  return (
    !!children && (
      <Fragment>
        {isEmbed && (
          <em className="d-inline-block mt-1 mb-2 medium text-gray-darker">
            <i className="icon-arrow-down8 small mr-0_25" aria-hidden /> Continue reading Edmunds Expert Rating below
          </em>
        )}
        <div className={childrenClassName}>{children}</div>
      </Fragment>
    )
  );
}

RatingScorecardEmbed.propTypes = {
  children: PropTypes.node,
  childrenClassName: PropTypes.string,
  isEmbed: PropTypes.bool,
};

RatingScorecardEmbed.defaultProps = {
  children: null,
  childrenClassName: null,
  isEmbed: true,
};
