import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { BADGE } from 'client/data/models/type-rankings-entities';
import { buildBadgesPath, TypeRankingsFeaturesModel } from 'client/data/models/type-rankings-features';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import './top-rated-ribbon.scss';

export const TOP_RATED_BADGE_PATH = '/img/top-rated/2023/badge-white.svg';

export function TopRatedRibbonUI({ className, stylesOverrides, isTopRated }) {
  if (!isTopRated) {
    return null;
  }

  // Used inline styles to avoid adding extra critical css key
  return (
    <div
      className={classnames('top-rated-ribbon', className)}
      style={{
        position: 'absolute',
        top: '5px',
        width: '140px',
        height: '32px',
        ...stylesOverrides,
      }}
    >
      <img
        width={120}
        src={getStaticImageUrl(TOP_RATED_BADGE_PATH)}
        alt="Top Rated vehicle"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </div>
  );
}

TopRatedRibbonUI.propTypes = {
  className: PropTypes.string,
  isTopRated: PropTypes.bool,
  stylesOverrides: PropTypes.objectOf(PropTypes.string),
};

TopRatedRibbonUI.defaultProps = {
  className: null,
  isTopRated: false,
  stylesOverrides: null,
};

const stateToPropsConfig = {
  isTopRated: bindToPath(({ vehicleParams }) => buildBadgesPath(vehicleParams), TypeRankingsFeaturesModel, badges =>
    badges.some(badge => badge === BADGE.TOP_RATED)
  ),
};

export const TopRatedRibbon = connectToModel(TopRatedRibbonUI, stateToPropsConfig);

TopRatedRibbon.propTypes = {
  vehicleParams: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    submodelSlug: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
  stylesOverrides: PropTypes.objectOf(PropTypes.string),
};

TopRatedRibbon.defaultProps = {
  className: null,
  stylesOverrides: null,
};
