export const EV_TESTED_DATA_START_DATE = 2020;

export const DEFAULT_PERSONALIZATION_PARAMS = {
  milesPerYear: 15000,
  dailyCommute: 25,
  cityPercents: 55,
  dailyRange: 19,
};

export const COST_ICON_BY_ENGINE_TYPE_MAP = {
  electric: 'icon-power2',
  'plug-in hybrid': 'icon-power2',
  hybrid: 'icon-hybrid_mpg',
  default: 'icon-gas',
};
