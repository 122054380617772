import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { EventToolbox } from 'client/utils/event-toolbox';
import { EngagementHandler } from 'client/engagement-handlers';
import { FilmStripCarouselEngagementHandler } from 'client/engagement-handlers/filmstrip-carousel-engagement-handler/filmstrip-carousel-engagement-handler';
import { FILMSTRIP_CAROUSEL_EVENTS } from './events';
import { getCardWidth, getParentWidth, handleScrollLeftPos } from './utils/filmstrip-carousel-utils';

import './filmstrip-carousel.scss';

EngagementHandler.register(FilmStripCarouselEngagementHandler);

const PrevArrow = props => (
  <div className="left carousel-control">
    <button aria-label="previous slide" className={classnames('icon-prev', props.className)} onClick={props.onClick} />
  </div>
);
PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
PrevArrow.defaultProps = {
  onClick: null,
  className: '',
};

const NextArrow = props => (
  <div className="right carousel-control">
    <button aria-label="next slide" className={classnames('icon-next', props.className)} onClick={props.onClick} />
  </div>
);
NextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
NextArrow.defaultProps = {
  onClick: null,
  className: '',
};

/**
 * IMPORTANT NOTE: THE CAROUSEL WILL NOT WORK IF YOUR DIRECT DESCENDANTS() OF THE '.slick-track' ARE NOT '.slick-slide' ELEMENTS.
 */
export function FilmstripCarousel({ children, totalAdCount, noAdsRefresh, scrollByParentWidth, showArrows }) {
  const ref = useRef();
  const shouldAdsRefresh = useRef(false);
  const currentIndex = useRef(0);

  const handleScroll = useCallback(
    options => {
      const direction = options && options.prev ? -1 : 1;
      const cardWidth = getCardWidth(ref.current);
      const left = scrollByParentWidth ? getParentWidth(ref.current, cardWidth) : cardWidth;

      shouldAdsRefresh.current = true;
      const scrollByOption = {
        top: 0,
        left: left * direction,
        behavior: 'smooth',
      };
      ref.current.querySelector('.slick-list').scrollBy(scrollByOption);
    },
    [scrollByParentWidth]
  );

  const checkIndex = useCallback(
    (scrollLeft, fireTracking) => {
      const cardWidth = getCardWidth(ref.current);
      const nextIndex = Math.round(scrollLeft / cardWidth);

      if (nextIndex !== currentIndex.current) {
        if (fireTracking) {
          const contentType = 'content';
          EventToolbox.fireCustomEvent(FILMSTRIP_CAROUSEL_EVENTS.SWIPE, {
            target: ref.current,
            data: {
              currentIndex: currentIndex.current,
              nextIndex,
              totalAdCount,
              contentType,
              contentDetails: contentType,
              photoType: '',
              noAdsRefresh: noAdsRefresh || !shouldAdsRefresh.current,
            },
          });
          shouldAdsRefresh.current = false;
        }
        currentIndex.current = nextIndex;
      }
    },
    [noAdsRefresh, totalAdCount]
  );

  function onPrevClicked() {
    handleScroll({ prev: true });
  }

  function onNextClicked() {
    handleScroll({ next: true });
  }

  useEffect(() => {
    if (showArrows) {
      handleScrollLeftPos(ref.current, checkIndex, false);
    }
  }, [checkIndex, showArrows]);

  useEffect(() => {
    if (!showArrows) {
      return () => {};
    }
    const containerRef = ref.current;

    const scrollListener = debounce(() => handleScrollLeftPos(containerRef, checkIndex, true), 100);

    containerRef.querySelector('.slick-list').addEventListener('scroll', scrollListener, false);
    window.addEventListener('resize', scrollListener, false);

    containerRef.querySelector('.slick-list').scrollTo(0, 0);

    return () => {
      containerRef.querySelector('.slick-list').removeEventListener('scroll', scrollListener, false);
      window.removeEventListener('resize', scrollListener, false);
    };
  }, [checkIndex, children.length, noAdsRefresh, showArrows, totalAdCount]);

  return (
    <section className="simple-carousel" ref={ref}>
      {showArrows && <PrevArrow onClick={onPrevClicked} />}
      <article className="slick-list">
        <div className="slick-track d-flex">{children}</div>
      </article>
      {showArrows && <NextArrow onClick={onNextClicked} />}
    </section>
  );
}

FilmstripCarousel.propTypes = {
  children: PropTypes.node,
  totalAdCount: PropTypes.number,
  customEventData: PropTypes.shape({}),
  noAdsRefresh: PropTypes.bool,
  scrollByParentWidth: PropTypes.bool,
  showArrows: PropTypes.bool,
};

FilmstripCarousel.defaultProps = {
  children: null,
  totalAdCount: 4,
  customEventData: null,
  noAdsRefresh: false,
  scrollByParentWidth: false,
  showArrows: true,
};
