import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, xor, orderBy } from 'lodash';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { getEvIncentivesUrl } from 'site-modules/shared/utils/core-link-constructor';
import { AnimatedCollapse } from 'site-modules/shared/utils/collapse-utils';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { Link } from 'site-modules/shared/components/link/link';

export function IncentivesInsight({ incentives, makeName, modelName, zipCode, isPluginHybrid }) {
  const [expandedIncentives, setExpandedIncentives] = useState([]);
  const totalRebates = incentives && incentives.reduce((total, { rebateAmount }) => total + rebateAmount, 0);

  function onIncentiveClick({
    currentTarget: {
      dataset: { id },
    },
  }) {
    setExpandedIncentives(xor(expandedIncentives, [id]));
  }

  return (
    <div className="incentives-insight" data-tracking-parent="edm-entry-ev-tax-credits">
      <div className={classnames({ 'heading-4 text-center mb-0_25': totalRebates, 'mb-1_5': !totalRebates })}>
        {getPriceString(totalRebates, `No rebates available in ${zipCode}`)}
      </div>
      {!!totalRebates && (
        <div
          className={classnames('medium text-center mb-1_5', {
            'mb-1_5': get(incentives, 'length'),
            'mb-0_5': !get(incentives, 'length'),
          })}
        >
          Available Rebates. Restrictions apply.
        </div>
      )}
      {!!get(incentives, 'length') && (
        <ul className="list-unstyled mb-1">
          {orderBy(incentives, ({ rebateAmount }) => rebateAmount || 0, 'desc').map(
            ({ id, rebateAmount, subtype, restrictions }) => {
              const ItemTag = restrictions ? 'button' : 'div';
              const itemProps = restrictions
                ? {
                    onClick: onIncentiveClick,
                    'data-tracking-id': expandedIncentives.includes(id) ? 'view_content' : undefined,
                    'data-tracking-value': subtype,
                  }
                : {};

              return (
                <li key={id} className="incentive-item">
                  <ItemTag
                    {...itemProps}
                    className="d-flex py-1 px-0 fw-normal bg-white text-start border-0 rounded w-100 justify-content-between"
                    data-id={id}
                  >
                    <div className="mr-1">{subtype}</div>
                    <div className="d-flex">
                      {!!rebateAmount && <div className="fw-bold text-nowrap">{getPriceString(rebateAmount)}</div>}
                      {!!restrictions && (
                        <i
                          className={classnames('text-blue-50 ml-1', {
                            'icon-arrow-down4': !expandedIncentives.includes(id),
                            'icon-arrow-up4': expandedIncentives.includes(id),
                          })}
                          aria-hidden
                        />
                      )}
                    </div>
                  </ItemTag>
                  {!!restrictions && (
                    <AnimatedCollapse isOpen={expandedIncentives.includes(id)}>
                      <div className="medium mb-1">
                        Restrictions: <ContentFragment componentToUse="span">{restrictions}</ContentFragment>
                      </div>
                    </AnimatedCollapse>
                  )}
                </li>
              );
            }
          )}
        </ul>
      )}
      <Link
        to={getEvIncentivesUrl({ makeName, modelName })}
        className="d-flex w-100 justify-content-between align-items-center text-blue-40"
        data-tracking-id="view_incentives_deals"
      >
        <span>See All {{ true: 'PHEV', false: 'EV' }[isPluginHybrid]} incentives</span>
        <i className="icon-arrow-right3 medium" aria-hidden />
      </Link>
    </div>
  );
}

IncentivesInsight.propTypes = {
  makeName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  incentives: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      rebateAmount: PropTypes.number,
      subtype: PropTypes.string,
      restrictions: PropTypes.string,
    })
  ),
  zipCode: PropTypes.string,
  isPluginHybrid: PropTypes.bool,
};

IncentivesInsight.defaultProps = {
  incentives: null,
  zipCode: null,
  isPluginHybrid: false,
};
