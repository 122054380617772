import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const TrimPricingBarsMap = {
  pricing_module_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    'expand_pricing_module'
  ),
  pricing_module_collapse: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PRICING_DETAILS,
    'collapse_pricing_module'
  ),
  view_pricing_bar_details: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_PRICING_DETAILS,
    'view_pricing_bar_details'
  ),
  view_pricing_bar_configure_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_CONFIGURE_VEHICLE,
    'view_pricing_bar_details'
  ),
  view_pricing_bar_srp_details: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'view_pricing_bar_details'
  ),
  pricing_bar_text_link: TrackingEvent.actionCompleted(TrackingConstant.VIEW_PRICING_DETAILS, 'pricing_bar_text_link'),
  pricing_bar_text_configure_link: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_CONFIGURE_VEHICLE,
    'pricing_bar_text_link'
  ),
  pricing_bar_text_srp_link: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'pricing_bar_text_link'
  ),
  pricing_bar_shared_pricing_cta: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_PRICING_DETAILS,
    'pricing_bar_shared_cta'
  ),
  pricing_bar_shared_configure_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_CONFIGURE_VEHICLE,
    'pricing_bar_shared_cta'
  ),
  pricing_bar_shared_srp_cta: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    'pricing_bar_shared_cta'
  ),
};
