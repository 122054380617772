import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';
import { TypeRankingsEntities } from 'client/data/models/type-rankings-entities';
import { buildRankingsSubsegmentLink } from 'site-modules/shared/utils/upper-funnel/type-rankings';
import { VEHICLES_NUM_TO_RANK } from 'site-modules/shared/constants/upper-funnel/type-rankings';

import './type-rankings-link.scss';

/**
 * This map is used for subsegments which are exist in several types. It helps to avoid 2 links with similar text one-by-one
 * key - displayName - subsegment name
 * value - edmundsTypeCategory - which type should display the subsegment
 */
const DUPLICATE_RESOLVER = {
  'Plug-in hybrids': 'Hybrid',
  'Luxury plug-in hybrid cars': 'Hybrid',
  'Luxury plug-in hybrid SUVs': 'Hybrid',
};

const SINGULAR_EXCEPTION_MAP = {
  hatche: 'hatch',
};

export function TypeRankingsLink({
  segmentRating,
  className,
  suppressDuplicate,
  displaySideArrow,
  rankNumberClassName,
  isLink,
  children,
  ...linkProps
}) {
  if (!segmentRating) {
    return null;
  }
  const {
    rank,
    editorialSegment: { displayName, edmundsTypeCategory, segmentRatings },
  } = segmentRating;

  const duplicateResolverType = DUPLICATE_RESOLVER[displayName] || edmundsTypeCategory;
  if (
    segmentRatings.filter(({ rating }) => rating).length < VEHICLES_NUM_TO_RANK ||
    (suppressDuplicate && duplicateResolverType !== edmundsTypeCategory)
  ) {
    return null;
  }

  let singularDisplayName = displayName.trim().slice(0, -1);
  Object.keys(SINGULAR_EXCEPTION_MAP).forEach(exclusion => {
    singularDisplayName = singularDisplayName.includes(exclusion)
      ? singularDisplayName.replace(exclusion, SINGULAR_EXCEPTION_MAP[exclusion])
      : singularDisplayName;
  });

  const content = children || (
    <Fragment>
      <span className={rankNumberClassName}>#{rank}</span> {singularDisplayName}{' '}
      {displaySideArrow && <i className="icon-arrow-right4 xsmall text-primary-darker" aria-hidden />}
    </Fragment>
  );

  return isLink ? (
    <Link
      className={classnames('type-rankings-link', className)}
      to={buildRankingsSubsegmentLink(edmundsTypeCategory, displayName)}
      data-tracking-id="view_vehicle_ranking"
      data-tracking-value={`#${rank} ${singularDisplayName}`.replace(/\s/g, '_')}
      {...linkProps}
    >
      {content}
    </Link>
  ) : (
    <span className={classnames('type-rankings-link', className)}>{content}</span>
  );
}

TypeRankingsLink.propTypes = {
  segmentRating: TypeRankingsEntities.SegmentRating,
  className: PropTypes.string,
  rankNumberClassName: PropTypes.string,
  children: PropTypes.node,
  suppressDuplicate: PropTypes.bool,
  displaySideArrow: PropTypes.bool,
  isLink: PropTypes.bool,
};

TypeRankingsLink.defaultProps = {
  segmentRating: null,
  className: null,
  rankNumberClassName: null,
  children: null,
  suppressDuplicate: false,
  displaySideArrow: false,
  isLink: true,
};
