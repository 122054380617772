import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { get } from 'lodash';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { withCoreMediaWrapper } from 'site-modules/shared/components/core-page/core-media-wrapper/core-media-wrapper';
import { OVERLAY_TYPE, PHOTO_ROW_THRESHOLD } from 'site-modules/core-page/utils/photo-gallery';

import { PhotoGalleryPhoto } from './photo-gallery-photo';

export function PhotoGalleryRowUI({ coreMedia, params, isPreprod, hasSpinPhotos, trackPhotosProvider }) {
  const photos = get(coreMedia, 'allMedia.photo');

  const startIndex = isPreprod ? 1 : 0;
  const renderPhotos =
    get(photos, 'length', 0) >= PHOTO_ROW_THRESHOLD && photos.slice(startIndex, startIndex + PHOTO_ROW_THRESHOLD);

  useEffect(() => {
    trackPhotosProvider(renderPhotos);
  }, [trackPhotosProvider, renderPhotos]);

  return (
    <Row className="photo-wrapper d-none d-lg-flex mt-0_5">
      {renderPhotos ? (
        renderPhotos.map((photo, index) => {
          let overlayType;

          switch (index) {
            case 0: {
              overlayType = hasSpinPhotos ? OVERLAY_TYPE.PHOTOS_360 : undefined;
              break;
            }
            case PHOTO_ROW_THRESHOLD - 1: {
              overlayType = OVERLAY_TYPE.REST_PHOTOS;
              break;
            }
            default: {
              break;
            }
          }

          return (
            <Col xs={6} md={3} key={photo.src}>
              <PhotoGalleryPhoto
                media={coreMedia.allMedia}
                photoData={photo}
                params={params}
                isPreprod={isPreprod}
                totalCount={coreMedia.totalCount}
                overlayType={overlayType}
                hasSpinPhotos={hasSpinPhotos}
                index={index + 1}
              />
            </Col>
          );
        })
      ) : (
        // Empty one for critical css
        <Col xs={6} md={3} />
      )}
    </Row>
  );
}

PhotoGalleryRowUI.propTypes = {
  params: CorePageParams.isRequired,
  trackPhotosProvider: PropTypes.func.isRequired,
  coreMedia: PropTypes.shape({
    totalCount: PropTypes.number,
    allMedia: PropTypes.shape({
      photos: PropTypes.arrayOf(PropTypes.shape({})),
      videos: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  hasSpinPhotos: PropTypes.bool,
  isPreprod: PropTypes.bool,
};

PhotoGalleryRowUI.defaultProps = {
  coreMedia: null,
  hasSpinPhotos: false,
  isPreprod: false,
};

export const PhotoGalleryRow = withCoreMediaWrapper(PhotoGalleryRowUI);
