import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  InsightCreativeConfigDefaultProps,
  InsightCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { INSIGHT_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';

import './insight-site-served-ad.scss';

export function InsightHorizontalSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  mobile,
  position,
  wrapperClass,
  ivtClassName,
  style,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    creativeConfigData: {
      photoDisclaimer,
      photo,
      disclaimerCopy,
      lineItemId,
      siteServedCreativeId,
      cta,
      isConquest,
      target,
      headline,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;
  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, cta);
    },
    [adLoadTrackingListener, cta]
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';

  /*
   * Image Component
   */
  const imageComponent = (
    <Fragment>
      {isConquest ? (
        <ConquestRibbon classes="mt-0_75" style={{ position: 'absolute', left: 0, top: 0, height: '20px' }} />
      ) : (
        <ConquestRibbon
          classes="mt-0_75"
          style={{ position: 'absolute', left: 0, top: 0, height: '20px' }}
          text="Did you know?"
          type="did-you-know"
        />
      )}
      <div className="img-wrapper float-left mr-1">
        <img
          className={classnames('core-img img-fluid d-block mx-auto w-100 h-100', {
            'has-img-disclaimer': !!photoDisclaimer,
            background: !photo,
          })}
          style={{ maxHeight: '73px', objectFit: 'contain' }}
          src={photo}
          alt=""
          loading="lazy"
        />
        {photoDisclaimer && (
          <div className="pl-1 pl-lg-0_5 pb-0_5 pb-lg-0_25 text-gray-darker size-10">{photoDisclaimer}</div>
        )}
      </div>
    </Fragment>
  );

  // /*
  //  * CTA component.
  //  */
  const ctaBtn = (
    <div
      className={classnames('cta-container cta btn btn-sm text-transform-none size-16 text-blue-50 p-0', {
        'd-block': mobile,
      })}
    >
      <CtaButtonText text={cta} />
    </div>
  );

  const disclaimer = disclaimerCopy && (
    <Disclaimer disclaimerText={disclaimerCopy} buttonClasses="pos-a bottom-0 right-0 pb-0_5 pr-0_5" />
  );
  const nativeStyle = INSIGHT_AD.NATIVE_STYLE;
  return (
    <div
      className={classnames('insight-site-served-ad-unit pos-r bg-white rounded-10', wrapperClass, ivtClassName)}
      style={{ overflow: 'hidden', ...style }}
    >
      <div data-tracking-parent={nativeStyle} data-viewability={`${nativeStyle}-${position}`} ref={adRef}>
        <DfpTracking
          lineItemId={lineItemId}
          siteServedCreativeId={siteServedCreativeId}
          mobile={mobile}
          nativeStyle={nativeStyle}
          position={position}
          slotRenderEndListener={loadTrackingListener}
          siteServedAdsUpdater={siteServedAdsUpdater}
        />
        {adTrackers}
        <a
          href={ctaURL}
          target={ctaTarget}
          rel="noopener noreferrer"
          onClick={adClickTrackingListener}
          className="site-served-ad-link no-focus text-decoration-none overflow-hidden d-block py-1_5 px-3_5"
        >
          <AdLabel styles={{ top: '8px', right: '8px' }} />
          {imageComponent}
          <div className="text-cool-gray-30 mb-0_5" style={{ minHeight: '2.5rem' }}>
            <ContentFragment>{headline}</ContentFragment>
          </div>
          {ctaBtn}
        </a>
        {disclaimer}
      </div>
    </div>
  );
}

InsightHorizontalSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: InsightCreativeConfigPropTypes,
  wrapperClass: PropTypes.string,
  style: PropTypes.shape({}),
};

InsightHorizontalSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: InsightCreativeConfigDefaultProps,
  wrapperClass: null,
  style: null,
};

export const InsightHorizontalSiteServedAd = siteServedAdTrackingWrapper(InsightHorizontalSiteServedAdUI);
