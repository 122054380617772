import React from 'react';
import PropTypes from 'prop-types';
import { withSiteServedAdWrapper } from 'client/site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';
import { INSIGHT_AD } from 'client/site-modules/shared/components/native-ad/utils/constants';
import { InsightSiteServedAd } from 'client/site-modules/shared/components/native-ad/insight-site-served-ad/insight-site-served-ad';
import { InsightHorizontalSiteServedAd } from 'client/site-modules/shared/components/native-ad/insight-site-served-ad/insight-horizontal-site-served-ad';

import {
  AdComponentDefaultProps,
  AdComponentPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';

import {
  InsightCreativeConfigPropTypes,
  InsightCreativeConfigDefaultProps,
} from 'client/data/models/native-ads-creative-config';

export function InsightAdUI(props) {
  const { mobile, wrapperClass, position, creativeConfig, ivtClassName, style, horizontalLayout } = props;
  const adProps = { wrapperClass, ivtClassName, position, creativeConfig, style, mobile };

  return horizontalLayout ? <InsightHorizontalSiteServedAd {...adProps} /> : <InsightSiteServedAd {...adProps} />;
}

InsightAdUI.propTypes = {
  ...AdComponentPropTypes,
  creativeConfig: InsightCreativeConfigPropTypes,
  style: PropTypes.shape({}),
  horizontalLayout: PropTypes.bool,
};

InsightAdUI.defaultProps = {
  ...AdComponentDefaultProps,
  creativeConfig: InsightCreativeConfigDefaultProps,
  style: null,
  horizontalLayout: false,
};

const InsightAdWrapper = withSiteServedAdWrapper(InsightAdUI);

export function InsightAd(props) {
  return <InsightAdWrapper {...props} adType={INSIGHT_AD} />;
}
