import { get, minBy } from 'lodash';
import { VEHICLES_NUM_TO_RANK } from 'site-modules/shared/constants/upper-funnel/type-rankings';
import highPriceBug from 'site-modules/shared/components/deal/deal-type/images/high-price-bug.svg';
import fairPriceBug from 'site-modules/shared/components/deal/deal-type/images/fair-price-bug.svg';
import goodPriceBug from 'site-modules/shared/components/deal/deal-type/images/good-price-bug.svg';
import greatPriceBug from 'site-modules/shared/components/deal/deal-type/images/great-price-bug.svg';

export function getEditorialIcon(editorialRating) {
  if (editorialRating <= 7.1) {
    return {
      text: 'below avg',
      textColor: '#E37D27',
      icon: highPriceBug,
    };
  }

  if (editorialRating <= 7.6) {
    return {
      text: 'average',
      textColor: '#059993',
      icon: fairPriceBug,
    };
  }

  if (editorialRating <= 8) {
    return {
      text: 'good',
      textColor: '#0089AE',
      icon: goodPriceBug,
    };
  }

  return {
    text: 'great',
    textColor: '#0069BF',
    icon: greatPriceBug,
  };
}

export function getBestSegmentRating(segmentRatings, { submodelSlug }) {
  if (!segmentRatings) {
    return null;
  }

  // has rank, has enough ranked vehicles
  const filteredSegmentRatings = segmentRatings.filter(
    ({ rank, editorialSegment }) =>
      rank && get(editorialSegment, 'segmentRatings', []).filter(({ rating }) => rating).length >= VEHICLES_NUM_TO_RANK
  );

  // priority for rankings with matching slugRankedSubmodel
  return (
    minBy(filteredSegmentRatings.filter(({ slugRankedSubmodel }) => slugRankedSubmodel === submodelSlug), 'rank') ||
    minBy(filteredSegmentRatings.filter(({ slugRankedSubmodel }) => !slugRankedSubmodel), 'rank')
  );
}
